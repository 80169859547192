<template>
    <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="8">
            <div class="grid-content bg-purple-light">
                <el-input v-model="username" placeholder="用户名"></el-input>
                <el-input placeholder="密码" v-model="password" show-password></el-input>
                <el-button type="primary" v-on:click="login">登录</el-button>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                username: '',
                password: '',
            }
        },
        created: function () {
            // 组件创建完后获取数据，
            // 此时 data 已经被 observed 了
            console.log('created');
            this.checkLoginStatus()
        },
        methods:{
            checkLoginStatus(){
                if (this.$sess.get('accessToken')) {
                    this.redirectPage()
                }
            },
            login(){
                let param = {'username':this.username,'password':this.password}
                this.$httpUtil.post(this.$urlConstant.login.pwdLoginPost,param,(responseData)=>{
                    //判断是否是湘雅的运营者或管理员
                    console.log('tn:'+responseData.data.tn);
                    console.log('siteId:'+responseData.data.siteId);
                    console.log('username:'+responseData.data.username);
                    if (process.env.VUE_APP_SITE_ID === responseData.data.siteId) {
                        this.$sess.login(responseData)
                        this.redirectPage()
                    }else {
                        this.$message.error('没有权限')
                    }

                },this)
            },
            redirectPage(){
                this.$router.push({name:'MarkList'})
                // let jsonRoles = this.$sess.get('roles')
                // let roles = JSON.parse(jsonRoles)
                // console.log('roles',roles[0])
                // if (roles[0] == 'admin'){
                //     this.$router.push({name:'SiteUserList'})
                // }else if (roles[0] == 'site_admin') {
                //     this.$router.push({name:'ArticleList'})
                // }else {
                //     this.$message.error("您没有权限");
                // }
            }
        }
    }
</script>

<style scoped>

</style>
